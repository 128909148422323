import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import networking_icon from "../../images/icon/networking.png";
import gathering_icon from "../../images/icon/gathering.png";
import gathering_icon_white from "../../images/icon/gathering_white.png";
import { colors } from "../../styles/colors";
import arrow_down_white from "../../images/icon/arrow_down_white.png";
import arrow_down_dark from "../../images/icon/arrow_down_dark.png";
import { EventItemData } from "../../types";

interface EventItemProps {
  /*title: string;
  location: string;
  timeAndDate: string;
  description: string;
  link: string;
  icon: string;
  variant: string;
  isOpen: boolean;
  handleOnClick: () => void;*/
  eventItem: EventItemData;
  isOpen: boolean;
  handleOnClick: () => void;
}

interface StylingProps {
  variation: string;
}

interface ActiveProps {
  isActive: boolean;
}

const StEventWrapper = styled.div<StylingProps>`
  border: 1px solid red;
  font-family: "Asap Condensed", sans-serif;
  margin-top: 2rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.variation === "lightGrey"
      ? "#a4abb3"
      : props.variation === "darkGrey"
      ? "#ffffff"
      : props.variation === "lightGreen"
      ? "#cfe0af"
      : props.variation === "darkGreen"
      ? "#afcc7a"
      : "#000000"};
  background-color: ${(props) =>
    props.variation === "lightGrey"
      ? "#ffffff"
      : props.variation === "darkGrey"
      ? "#ccd7e6"
      : props.variation === "lightGreen"
      ? "#ffffff"
      : props.variation === "darkGreen"
      ? "#cfe0af"
      : "#ffffff"};
`;

const StEventContainer = styled.div<StylingProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.variation === "lightGrey"
      ? "#a4abb3"
      : props.variation === "darkGrey"
      ? "#5f6266"
      : props.variation === "lightGreen"
      ? "#cfe0af"
      : props.variation === "darkGreen"
      ? "#afcc7a"
      : "#ffffff"};
`;

const StEventTitleAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StDescriptionContainer = styled.div`
  transition: height 0.2s ease-in-out;
  overflow: hidden;
`;

const StDescription = styled.p`
  font-family: "Asap Condensed", sans-serif;
  letter-spacing: 0.2pt;
  margin: 0;
`;

const StH4 = styled.h4<StylingProps>`
  font-family: "Asap Condensed", sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0.8rem 0;
  letter-spacing: 0.2pt;
  color: ${(props) =>
    props.variation === "lightGreen" ||
    props.variation === "darkGreen" ||
    props.variation === "lightGrey"
      ? "#36393b"
      : "#ccd7e6"};
`;

const StTitle = styled.span`
  strong {
    font-weight: 600;
  }
`;

const StArrowIcon = styled.img<ActiveProps>`
  width: 20px;
  margin: 0.5rem;
  transform: ${(props) => (props.isActive ? "rotate(-180deg)" : "")};
`;

const StButton = styled.a<StylingProps>`
  color: ${colors.ihk_darkblue};
  font-family: "Asap Condensed", sans-serif;
  font-weight: 600;
  letter-spacing: 0.8pt;
  padding: 0.6rem 1.2rem;
  background-color: ${(props) =>
    props.variation === "lightGreen"
      ? "#ccd7e6"
      : props.variation === "darkGreen"
      ? "#ffffff"
      : "#ffffff"};

  &:hover {
    color: ${colors.ihk_darkblue};
  }
`;

const StIcon = styled.img`
  max-width: 39px;
  vertical-align: middle;
  padding: 0.5rem;
  margin-right: 0.5rem;
`;

const EventItem: React.FC<EventItemProps> = ({
  eventItem,
  isOpen,
  handleOnClick,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const contentEl = contentRef.current as HTMLDivElement;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <StEventWrapper variation={eventItem.variant}>
      <StEventContainer
        variation={eventItem.variant}
        onClick={() => handleOnClick()}
      >
        <StEventTitleAndIcon>
          <div>
            {eventItem.icon === "gathering" && eventItem.link !== "" ? (
              <StIcon src={gathering_icon} alt="" />
            ) : eventItem.icon === "gathering" && eventItem.link === "" ? (
              <StIcon src={gathering_icon_white} alt="" />
            ) : (
              <StIcon src={networking_icon} alt="" />
            )}
          </div>
          <StH4 variation={eventItem.variant}>
            <StTitle dangerouslySetInnerHTML={{ __html: eventItem.title }} />
            <br />
            {eventItem.timeAndDate !== "" ? (
              <span style={{ fontWeight: "bold" }}>
                {eventItem.timeAndDate}
              </span>
            ) : null}
            {eventItem.timeAndDate !== "" ? <span>&nbsp;</span> : null}
            {eventItem.location !== "" ? (
              <span>{eventItem.location}</span>
            ) : null}
          </StH4>
        </StEventTitleAndIcon>
        <div>
          {eventItem.variant !== "darkGrey" ? (
            <StArrowIcon src={arrow_down_dark} isActive={isOpen} />
          ) : (
            <StArrowIcon src={arrow_down_white} isActive={isOpen} />
          )}
        </div>
      </StEventContainer>
      <StDescriptionContainer style={{ height }}>
        <div ref={contentRef} style={{ padding: "1rem" }}>
          {isOpen && (
            <>
              <StDescription>
                {eventItem.description}

                {eventItem.link !== "" ? (
                  <>
                    <br />
                    <br />
                    <StButton
                      variation={eventItem.variant}
                      target="_blank"
                      href={eventItem.link}
                      rel={"noopener noreferrer"}
                    >
                      Anmelden
                    </StButton>
                  </>
                ) : null}
              </StDescription>
            </>
          )}
        </div>
      </StDescriptionContainer>
    </StEventWrapper>
  );
};

export default EventItem;
