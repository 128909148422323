import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  GridImg,
  GridItem,
  GridTextBody,
  GridWrapperSingleCol,
} from "../styles/GlobalStyles";

export interface Company {
  name: string;
  textContent: string;
}

const Companies = (): JSX.Element => {
  const companies: Record<string, Company> = {
    merck: {
      name: "Merck KGaA",
      textContent:
        "„Wir wollen mit Blick auf die Energiewende verstärkt in Zusammenhängen\n" +
        "    denken, also über die Effizienzsteigerungen einzelner Anlagen und\n" +
        "    Maschinen hinaus. Das verlangt auch, unternehmensübergreifend\n" +
        "    zusammenzuarbeiten. Das Netzwerk ETA-Metropol Rhein Main Neckar ist aus\n" +
        "    diesem Grund eine gute Plattform, da hier alle relevanten Akteure –\n" +
        "    von der Industrie über die Energiebranche bis hin zu Wissenschaft und\n" +
        "    Politik an einem Tisch sitzen und gemeinsam Lösungen entwickeln\n" +
        "    können.“ Stefan Müller Head of Energy Management & Technology",
    },
    vacuum: {
      name: "Vacuumschmelze GmbH & Co. KG",
      textContent:
        "„Das Netzwerk ETA-Metropol Rhein Main Neckar für Energieeffizienz und Klimaschutz bietet Unternehmen die Möglichkeit, sich mit Energieexperten aus Unternehmen auszutauschen,\n" +
        "um innovative Ansätze kennenzulernen und Verbesserungspotenziale für das eigene Unternehmen zu erkennen.\n" +
        "Durch eine Kooperation des ETA Netzwerkes mit der TU Darmstadt konnten wir im Bereich der Lüftungstechnik bereits erste Projekte umsetzen,\n" +
        "welche eine Reduzierung unseres energetischen Verbrauchs  erzielen.“ Patricia Borgwardt Beauftragte für Sicherheits-, Umwelt-, Energiemanagement\n",
    },
    pirelli: {
      name: "Pirelli Deutschland GmbH",
      textContent:
        "„Dem Umwelt- und Klimaschutz misst Pirelli weltweit seit vielen Jahren eine dominante Rolle bei. So auch am Standort Breuberg. " +
        "Dort erzeugt eine moderne Kraft-Wärme-Kopplungsanlage elektrische Energie und Nutzwärme. Das spart viel Primärenergie und CO2-Emissionen." +
        " Mit einer Photovoltaikanlage auf dem Dach unseres neuen Produktionsgebäudes sind wir einen weiteren Schritt in Richtung Dekarbonisierung gegangen. " +
        "Im Netzwerk ETA-Metropol Rhein Main Neckar werden wir unsere Strategie zur Klimaneutralität konsequent fortsetzen.” " +
        "Wolfgang Meier, Vorsitzender der Geschäftsführung Pirelli Deutschland",
    },
    schott: {
      name: "SCHOTT AG",
      textContent:
        "„Die kontinuierliche Verbesserung der Energieeffizienz steht bei SCHOTT schon seit Jahrzehnten auf der Agenda - aus betriebswirtschaftlichen Gründen " +
        "und als Beitrag zum Umweltschutz. Nachdem das Ziel „Klimaneutral bis 2030“ nun ein zentraler Bestandteil unserer Konzernstrategie ist, " +
        "wollen wir unsere Anstrengungen noch verstärken. Durch den Expertenaustausch im Energieeffizienznetzwerk ETA-Metropol Rhein Main Neckar " +
        "versprechen wir uns neue Impulse. Dabei hoffen wir von Best Practice Beispielen lernen zu können.“ " +
        "Matthias Hofmeister, Energiemanagement/- wirtschaft, SCHOTT AG",
    },
    hewi: {
      name: "HEWI GmbH",
      textContent:
        "„Wir gehören insbesondere durch unsere Kunststoffverarbeitung zu einer energieintensiven Branche. " +
        "Gemäß unserer Klimastrategie setzen wir auf Eigenenergieerzeugung, streben eine kontinuierliche Verbesserung unserer Energieeffizienz an " +
        "und möchten langfristig klimaneutral (Net Zero-Emissionen) werden. Das ist in der Umsetzung nicht immer einfach. " +
        "Nur gemeinsam können wir durch Zusammenarbeit und Austausch im Netzwerk ETA-Metropol Rhein Main Neckar für Energieeffizienz " +
        "und Klimaschutz die Herausforderungen meistern und einen noch größeren Beitrag für Umwelt- und Klimaschutz leisten. " +
        "Durch das Teilen von Ideen, neuen Erkenntnissen, Projektfortschritten und Erfolgen spornt uns das Netzwerk zusätzlich an und bringt alle " +
        "Teilnehmer in ihren Nachhaltigkeits- und Klimaschutzvorhaben voran. " +
        "Wir können uns gegenseitig ein Vorbild sein, voneinander lernen, uns unterstützen und neue Lösungen finden.“ " +
        "Kristin Heinemann, Nachhaltigkeits- und Klimamanagerin",
    },
    juwoe: {
      name: "Juwö Poroton-Werke",
      textContent:
        "„JUWÖ ist einer der führenden Mauerziegelhersteller Deutschlands. Nachhaltigkeit leben wir seit Generationen. " +
        "Der Anteil der Ziegelindustrie beträgt gerade mal 0,5% aller industriellen Emissionen. " +
        "In der Roadmap zur Klimaneutralität 2050 beschreibt die Ziegelindustrie gleichwohl einen konkreten Weg zur klimaneutralen Produktion" +
        ". Viele der darin beschriebenen Maßnahmen sind aber noch gar nicht erfunden, geschweige denn mengen- und kostenmässig umzusetzen" +
        ". Daher engagiert sich JUWÖ schon länger an einigen Netzwerken und Forschung, um eigene Ideen mit anderen zu teilen und die Inspiration anderer branchenübergreifend aufzunehmen" +
        ". Das ETA Netzwerk wird ein weiterer Baustein auf unserem Weg sein.“" +
        " Dipl.-Kfm. Stefan Jungk, Geschäftsführender Gesellschafter JUWÖ Poroton-Werke Ernst Jungk & Sohn GmbH",
    },
  };

  const data = useStaticQuery(graphql`
    query {
      merck: file(relativePath: { eq: "partner/merck-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vacuum: file(relativePath: { eq: "partner/vacuumschmelze-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pirelli: file(relativePath: { eq: "partner/pirelli-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schott: file(relativePath: { eq: "partner/schott-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hewi: file(relativePath: { eq: "partner/hewi-gmbh-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      juwoe: file(relativePath: { eq: "partner/juwoe_logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mustermann: file(relativePath: { eq: "partner/mustermann-ag-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const getStyleForCompanyImage = (
    companyName: string
  ): Record<string, string> => {
    switch (companyName) {
      case "vacuum":
        return { width: "65%" };
      case "schott":
        return { width: "88%" };
      case "hewi":
        return { width: "82%" };
      case "juwoe":
        return { width: "80%" };
      default:
        return { width: "100%" };
    }
  };

  return (
    <GridWrapperSingleCol>
      {Object.keys(companies).map(
        (company, index): ReactNode => {
          return (
            <GridItem key={index} id={company}>
              <GridImg className={"m-3"}>
                <Img
                  imgStyle={{ objectFit: "contain" }}
                  style={getStyleForCompanyImage(company)}
                  fluid={data && data[company].childImageSharp.fluid}
                />
              </GridImg>

              <GridTextBody>
                <h4>{companies[company].name}</h4>
                <p>{companies[company].textContent}</p>
              </GridTextBody>
            </GridItem>
          );
        }
      )}
    </GridWrapperSingleCol>
  );
};

export default Companies;
