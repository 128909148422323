import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

export interface linkBlock {
  link: string;
}
const ihkLinks: Record<string, linkBlock> = {
  pfalz: {
    link: "https://www.pfalz.ihk24.de/",
  },
  rheinhessen: {
    link: "https://www.rheinhessen.ihk24.de/",
  },
  darmstadt: {
    link: "https://www.darmstadt.ihk.de/",
  },
  wiesbaden: {
    link: "https://www.ihk-wiesbaden.de/",
  },
};

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  @media only screen and (max-width: 991px) {
    align-items: center;
  }
`;

const FlexItem = styled.a`
  min-width: 200px;
  flex: 23% 0 1;
  max-height: 60px;
  margin: 1rem 0;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

interface ImageProps {
  logo: string;
  fluid: unknown;
}

const StGatsbyImg = styled(Img)`
  max-height: ${(props: ImageProps) =>
    props.logo === "wiesbaden" ? "57px" : "100%"};

  @media only screen and (max-width: 1200px) {
    max-height: ${(props: ImageProps) =>
      props.logo === "wiesbaden" ? "48px" : "100%"};
  }
`;

const IHKLogos = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      pfalz: file(relativePath: { eq: "ihk-pfalz.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rheinhessen: file(relativePath: { eq: "ihk-rheinhessen.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darmstadt: file(relativePath: { eq: "ihk-darmstadt.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wiesbaden: file(relativePath: { eq: "ihk_wiesbaden.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <FlexWrapper>
      {data &&
        Object.keys(data).map(
          (logo, index): ReactNode => {
            return (
              <FlexItem
                key={index}
                href={ihkLinks[logo].link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <StGatsbyImg
                  fluid={data && data[logo].childImageSharp.fluid}
                  logo={logo}
                />
              </FlexItem>
            );
          }
        )}
    </FlexWrapper>
  );
};

export default IHKLogos;
