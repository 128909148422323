import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  RoundGridImg,
  GridItem,
  GridTextBody,
  GridWrapperMultiCol,
} from "../styles/GlobalStyles";
import { colors } from "../styles/colors";
import styled from "styled-components";

export interface AspData {
  standort: string;
  asp: string;
  mail: string;
  phone: string;
}

const NoImagePlaceholder = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${colors.ihk_darkgrey};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AsPartner = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      neckar: file(relativePath: { eq: "contact/engel-klaus-peter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pfalz: file(relativePath: { eq: "contact/sarah_sousa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rheinhessen: file(relativePath: { eq: "contact/krause-martin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darmstadt: file(
        relativePath: { eq: "contact/alice-sophie_thomas-ihk.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wiesbaden: file(relativePath: { eq: "contact/van_vliet_marie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const contactData: Record<string, AspData> = {
    darmstadt: {
      standort: "IHK Darmstadt Rhein Main Neckar",
      asp: "Alice Sophie Thomas",
      mail: "alice.thomas@darmstadt.ihk.de",
      phone: "6151-8711197",
    },
    rheinhessen: {
      standort: "IHK Rheinhessen",
      asp: "Martin Krause",
      mail: "martin.krause@rheinhessen.ihk24.de",
      phone: "6721-914115",
    },
    pfalz: {
      standort: "IHK Pfalz",
      asp: "Sarah Sousa",
      mail: "sarah.sousa@pfalz.ihk24.de",
      phone: "621-59042130",
    },
    wiesbaden: {
      standort: "IHK Wiesbaden",
      asp: "Marie van Vliet",
      mail: "m.vvliet@wiesbaden.ihk.de",
      phone: "611-1500189",
    },
  };

  const personSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="currentColor"
      className="bi bi-person"
      viewBox="0 0 16 16"
    >
      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
    </svg>
  );

  return (
    <GridWrapperMultiCol>
      {data &&
        Object.keys(contactData).map(
          (aspartner, index): ReactNode => {
            return (
              <GridItem key={index}>
                <RoundGridImg className={"m-3"}>
                  {data && !data[aspartner] ? (
                    <NoImagePlaceholder>{personSvg}</NoImagePlaceholder>
                  ) : (
                    <Img
                      fluid={data && data[aspartner].childImageSharp.fluid}
                      style={{ width: "100%" }}
                    />
                  )}
                </RoundGridImg>
                <GridTextBody>
                  <p className={"m-0"}>{contactData[aspartner].asp}</p>
                  <p className={"m-0"}>{contactData[aspartner].standort}</p>
                  <p className={"m-0"}>
                    Tel.: &nbsp;
                    <a
                      href={`tel:+49${contactData[aspartner].phone.replace(
                        "-",
                        ""
                      )}`}
                    >
                      0{contactData[aspartner].phone}
                    </a>
                  </p>
                  <p className={"m-0"}>
                    <a href={"mailto:" + contactData[aspartner].mail}>
                      {contactData[aspartner].mail}
                    </a>
                  </p>
                </GridTextBody>
              </GridItem>
            );
          }
        )}
    </GridWrapperMultiCol>
  );
};

export default AsPartner;
