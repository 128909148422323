export const eventList2023 = [
  {
    title:
      "Netzwerktreffen Q1 2023: <strong>Forschung und Entwicklung für mehr Energieeffizienz in der Produktion</strong>",
    location: "bei der TU Darmstadt",
    timeAndDate: "23.03.2023 von 15:00 bis 18:00 Uhr",
    variant: "lightGreen",
    link:
      "https://www.ihk.de/darmstadt/system/veranstaltungssuche/vstdetail-antrago/5137470/34055?terminId=34055",
    icon: "networking",
    description:
      "Die Energiewende stellt Unternehmen des verarbeitenden und produzierenden Gewerbes vor neue Herausforderungen. Steigende Energiepreise, volatile Energiemärkte und anspruchsvolle Umweltstandards fordern Unternehmen heraus, ihren Energieverbrauch zu reduzieren und Effizienzpotentiale in der Produktion zu identifizieren. Diese Herausforderungen können jedoch auch Chancen bieten, Kosten zu reduzieren und die Wettbewerbsfähigkeit zu verbessern. Bei unserem ersten Netzwerktreffen in diesem Jahr besuchen wir gemeinsam die ETA-Fabrik der TU Darmstadt und erfahren mehr über die Zukunft der Produktion und wie Unternehmen erfolgreich in die Transformation hin zu einem treibhausgasneutralen Energiesystem investieren können. Mit ihrer Version von einer energieeffizienten, energieflexiblen und ressourceneffizienten industriellen Produktion von morgen, möchte die Forschungsgruppe „ETA – Energietechnologien und Anwendungen in der Produktion“ einen Beitrag zu einer CO2-neutralen Produktion leisten. ",
  },
  {
    title:
      "Netzwerktreffen Q2 2023: <strong>Kreislaufwirtschaft und Ressourceneffizienz</strong>",
    location: "bei MEWA Textil-Service AG & Co. Management OHG",
    timeAndDate: "22.06.2023 von 15:00 bis 18:00 Uhr",
    description:
      "In einer Welt, in der Energiekosten stetig steigen, Lieferketten anfälliger sind, Rohstoffpreise zunehmen und die geopolitische Lage unsicher ist, erkennen immer mehr Unternehmen in der Industrie das Potenzial der Kreislaufwirtschaft – auch für den Klimaschutz. Durch eine Kreislaufwirtschaft kann die Branche ihren Ressourcenverbrauch und Corporate Carbon Footprint reduzieren und damit einen wichtigen Beitrag zur Erreichung der Klimaneutralität leisten. Doch das Konzept der Kreislaufwirtschaft ist nicht neu: Mewa hat diesen Gedanken schon seit Jahrzehnten in ihrem Geschäftsmodell verankert. Das Mewa-Prinzip Textilsharing folgt dem Ansatz „Nutzen statt Besitzen“: Betriebstextilien werden bereitgestellt, vielfach genutzt, von Mewa gewaschen und wieder angeliefert. Bei unserem Netzwerktreffen gibt Mewa Einblicke, wie das Thema Kreislaufwirtschaft im Unternehmen angegangen wird und wie Unternehmen damit ihre Umweltbelastung reduzieren können.",
    icon: "networking",
    variant: "lightGreen",
    link:
      "https://www.ihk.de/darmstadt/system/veranstaltungssuche/vstdetail-antrago/5137470/34056?terminId=34056",
  },
  {
    title:
      "Netzwerktreffen Q3 2023:<br/> <strong>Energieversorgung durch eigene Erzeugung und Speicher oder durch 100% Netzbezug?</strong>",
    location: "",
    timeAndDate: "05.09.2023 von 15:00 bis 18:00 Uhr",
    description:
      "Wie können Unternehmen Ihre eingesetzte Energie von fossilen Energieträgern hin zu erneuerbaren Energien umzustellen, um die Emissionsziele zu erreichen? Dieser Frage müssen sich Unternehmen aktuell stellen und für sich individuell beantworten. Dabei müssen verschiedene Aspekte berücksichtig werden: welcher Energieträger passt auf die jeweilige Situation des Unternehmens? Soll die Energie vor Ort umgewandelt werden oder die Versorgung über Trailer, Batterien und Rohrnetze geschehen? Dabei handelt es sich oft um Entscheidungen, die das Unternehmen über eine lange Zeit hinweg begleiten. Unsere Mitgliedsunternehmen haben eigene Ideen und gute Gründe, bestimmte Maßnahmen umzusetzen oder auch auf konkrete Energieträger und Technologien zu verzichten. Bei diesem Netzwerktreffen erfahren wir mehr über die Planungen für eine eigene Photovoltaik-Freiflächenanlage und ein Forschungsprojekt über die Spaltung von Ammoniak eines unserer Mitglieder. Im Anschluss diskutieren wir über die Vor- und Nachteile von Eigenerzeugung und Netzbezug.",
    link:
      "https://www.ihk.de/darmstadt/system/veranstaltungssuche/vstdetail-antrago/5137470/36505?terminId=36505",
    icon: "networking",
    variant: "lightGreen",
  },
  {
    title: "<strong>IHK Energie- und Klimasymposium 2023</strong>",
    timeAndDate: "07.12.2023 von 16:00 bis 18:00 Uhr,",
    location: "Ort wird noch bekannt gegeben",
    description:
      "Zum Jahresabschluss planen wir auch in diesem Jahr wieder ein Energie- und Klimasymposium. Dort möchten wir gemeinsam mit Ihnen sowie weiteren Vertretern der Wirtschaft, Wissenschaft und Politik einerseits aktuelle Herausforderungen und geplante Regularien für Energie und Klimaschutz näher beleuchten und andererseits den Blick perspektivisch auf die Energieversorgung 2030 richten. Mögliche Schwerpunkte können unter anderem beim Energieeffizienzgesetz, den Klimaschutzverträgen oder auch beim Industriestrompreis liegen. Um die Thematik möglichst aktuell zu halten, erfolgt die finale Abstimmung der Agenda allerdings erst im Herbst. Gerne beziehen wir auch Ihre Themenwünsche mit ein. Im Anschluss laden wir Sie zum Get-Together ein.",
    icon: "gathering",
    variant: "darkGreen",
    link:
      "https://www.ihk.de/darmstadt/system/veranstaltungssuche/vstdetail-antrago/5137470/34400?terminId=34400",
  },
];
