import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  GridWrapperMultiCol,
  GridImg,
  GridItem,
  GridTextBody,
} from "../styles/GlobalStyles";

export interface tile {
  title: string;
  textContent: string;
  imageName: string;
}

const TileGroup = (): JSX.Element => {
  const tiles: Record<string, tile> = {
    costs: {
      title: "Kosten optimieren",
      textContent:
        "Maßnahmen in Energieeffizienz und Klimaschutz senken nachhaltig Ihre Kosten. Das ist gut für Ihr Unternehmen, die Umwelt und den Klimaschutz.",
      imageName: "icon.png",
    },
    potential: {
      title: "Fakten kommunizieren",
      textContent:
        "Ein erfolgreicher Klimaschutz ist nur mit der Wirtschaft möglich. Gemeinsam informieren wir über Fakten und kommunizieren industriepolitische Statements.",
      imageName: "icon.png",
    },
    funding: {
      title: "Förderungen nutzen",
      textContent:
        "Fördermöglichkeiten sind ein wichtiger Baustein auf dem Weg zur Klimaneutralität. Ganz gleich ob es sich um die individuelle Finanzierung von Maßnahmen oder um gemeinsame Forschungsprojekte handelt.",
      imageName: "icon.png",
    },
    learn: {
      title: "Voneinander lernen",
      textContent:
        "Lernen Sie bei Netzwerktreffen andere Unternehmen und Klimaschutzstrategien kennen. Nutzen Sie den wertvollen Erfahrungsaustausch und profitieren Sie von Expertenvorträgen sowie Unternehmensbesichtigungen im Rahmen der Roadshows.",
      imageName: "icon.png",
    },
  };
  const data = useStaticQuery(graphql`
    query {
      potential: file(relativePath: { eq: "icon/fact_check_black.svg" }) {
        publicURL
      }
      costs: file(relativePath: { eq: "icon/costs.svg" }) {
        publicURL
      }
      funding: file(relativePath: { eq: "icon/funding.svg" }) {
        publicURL
      }
      learn: file(relativePath: { eq: "icon/learn.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <GridWrapperMultiCol>
      {Object.keys(tiles).map(
        (tile, index): ReactNode => {
          return (
            <GridItem key={index}>
              <GridImg>
                <img className="icon" src={data && data[tile].publicURL} />
              </GridImg>

              <GridTextBody>
                <h4>{tiles[tile].title}</h4>
                <p>{tiles[tile].textContent}</p>
              </GridTextBody>
            </GridItem>
          );
        }
      )}
    </GridWrapperMultiCol>
  );
};

export default TileGroup;
