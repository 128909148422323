import React, { useState } from "react";
import EventItem from "./EventItem";
import { EventItemData } from "../../types";

interface EventsProps {
  items: Array<EventItemData>;
}

const Events: React.FC<EventsProps> = ({ items }) => {
  const [currentIdx, setCurrentIdx] = useState(-1);
  const handleOnClick = (index: number) => {
    setCurrentIdx(index);
  };

  return (
    <>
      {items.map((event, index) => {
        return (
          <EventItem
            key={index}
            eventItem={event}
            isOpen={index === currentIdx}
            handleOnClick={() => handleOnClick(index)}
          />
        );
      })}
    </>
  );
};

export default Events;
