export const eventList2022 = [
  {
    title:
      "Netzwerktreffen Q2 2022: <strong>Unternehmensstrategien zur Klimaneutralität</strong>",
    timeAndDate: "04.04.2022 von 15:00 bis 18:00 Uhr",
    location: "bei Pirelli Deutschland GmbH",
    link: "",
    icon: "networking",
    variant: "lightGrey",
    description:
      "Dem Umwelt- und Klimaschutz misst Pirelli weltweit seit vielen Jahren eine dominante Rolle bei. So auch am Standort in Breuberg. Dort erzeugt eine moderne Kraft-Wärme-Kopplungsanlage elektrische Energie und Nutzwärme. Das spart viel Primärenergie und CO2-Emissionen. Mit einer Photovoltaikanlage auf dem Dach des neuen Produktionsgebäudes wurde ein weiterer Schritt in Richtung Dekarbonisierung gegangen. Im Rahmen des ersten Netzwerktreffen besichtigen die Teilnehmer des Netzwerks ETA-Metropol Rhein Main Neckar das Werk und seine Energieerzeugungsanlagen. Ergänzt wird das Netzwerktreffen mit einem Vortrag zur Klima- und Nachhaltigkeitsstrategie, mit welcher Pirelli in der weltweiten Produktion bis 2030 CO2-Neutralität erzielen möchte.",
  },
  {
    title:
      "Netzwerktreffen Q3 2022: <strong>Energiemanagement und -beschaffung nachhaltig gedacht</strong>",
    timeAndDate: "04.07.2022 von 15:00 bis 18:00 Uhr",
    location: "bei Schott AG",
    link: "",
    variant: "lightGrey",
    icon: "networking",
    description:
      'Die kontinuierliche Verbesserung der Energieeffizienz steht bei der SCHOTT AG schon seit Jahrzehnten auf der Agenda. Einerseits aus betriebswirtschaftlichen Gründen und andererseits als Beitrag zum Umweltschutz. Mit dem Ziel "Klimaneutral bis 2030" als zentralem Bestandteil der Konzernstrategie, sollen diese Anstrengungen zukünftig noch verstärkt werden. Im Rahmen des zweiten Netzwerktreffens werden Einblicke in das nachhaltige Energiemanagement gegeben und zukunftsfähige Beschaffungsstrategien diskutiert. ',
  },
  {
    title: "Netzwerktreffen Q3 2022: Nachhaltigkeitsberichterstattung",
    timeAndDate: "19.09.2022 von 15:00 bis 18:00 Uhr",
    location: "Dentsply Sirona Deutschland GmbH",
    description:
      'Der Investitionsbedarf zur Erreichung der Klimaschutzziele ist gewaltig. Vor diesem Hintergrund entwickelt sich aktuell unter dem Begriff "Sustainable Finance" ein komplexer Rechtsrahmen, mit dem Ziel, privates Kapital in nachhaltige wirtschaftliche Aktivitäten zu lenken. Dentsply Sirona zeigt in seinem Nachhaltigkeitsbericht, wie ESG-Daten (ESG: Environment, Social, Governance) gemessen und analysiert werden können. Im Rahmen des dritten Netzwerktreffen besichtigen die Teilnehmer des Netzwerks ETA-Metropol Rhein Main Neckar den Firmensitz der Dentsply Sirona Deutschland GmbH und diskutieren neue Anforderungen und gute Beispiele einer Nachhaltigkeitsberichterstattung. ',
    variant: "lightGrey",
    icon: "networking",
    link: "",
  },
  {
    title: "IHK Energie- und Klimasymposium 2022",
    timeAndDate: "08.12.2022 von 16:00 bis 18:00 Uhr,",
    location: "Ort wird noch bekanntgegeben",
    description:
      "Das Thema Energie ist und bleibt allgegenwärtig. Die Strom- und Gaspreise sind in diesem Jahr aufgrund des russischen Angriffs auf die Ukraine explodiert, die deutsche Abhängigkeit vom russischen Gas hat die Situation auf den Energiemärkten verschärft. Alternative Energiequellen sollen die Versorgungssicherheit in Deutschland weiter gewährleisten. So wurde erst im November dem Weiterbetrieb der letzten drei deutschen Atomkraftwerke zur Sicherung der Stromversorgung zugestimmt, der Atomausstieg ist aber weiterhin geplant. Dafür gehen immer mehr erneuerbare Energien ans Netz, wovon sich eine günstige und klimafreundliche Energieversorgung versprochen wird - unabhängig von fossilen Energieträgern. Gleichzeitig wächst damit die Sorge um die Versorgungssicherheit in Deutschland: bleiben unsere Energieversorgungsnetze stabil? Wie wirken sich die veränderten Rahmenbedingungen auf die deutsche Wirtschaft aus?\n" +
      "Diesen und weiteren Fragen wollen wir uns auf unserem diesjährigen Energie- und Klimasymposium widmen. Zwei Impulsvorträge von Siemens Energy/EWR (angefragt) und TSB Energie geben eine Einführung in das Thema. Darauffolgend wird es eine Podiumsdiskussion mit Vertretern aus der Wirtschaft und der Politik geben. Anschließend bietet das Symposium einen Rahmen zum gemeinschaftlichen Austausch und Netzwerken mit regionaler Verköstigung.",
    link: "",
    icon: "gathering",
    variant: "darkGrey",
  },
];
