import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TileGroup from "../components/TileGroup";
import IHKLogos from "../components/IHKLogos";
import AsPartner from "../components/AsPartner";
import Companies from "../components/Companies";
import styled from "styled-components";
import { colors } from "../styles/colors";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import IHK_INFOGRAFIK from "../images/ihk_eta_metropol_infografik.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageMap from "image-map";
import Events from "../components/Event/Events";
import { eventList2023 } from "../components/Event/EventList2023";
import { eventList2022 } from "../components/Event/EventList2022";

const StHeadline = styled.h1`
  font-weight: 200;
  width: 100%;
  color: ${colors.ihk_darkblue};
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${colors.ihk_lightblue};
`;
const StH2 = styled.h2`
  font-weight: 200;
  width: 100%;
  color: ${colors.ihk_darkblue};
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid ${colors.ihk_lightblue};
`;

const StH3 = styled.h3`
  font-weight: 600;
  width: 100%;
  color: ${colors.ihk_darkblue};
  text-align: center;
  background-color: ${colors.ihk_lightblue};
  border-bottom: 3px solid ${colors.ihk_green};
  letter-spacing: 0.8pt;
  padding: 0.5rem;
  font-size: 1.2rem;
`;

const StH4 = styled.h4`
  font-weight: 600;
  width: 100%;
  color: ${colors.ihk_darkblue};
  text-align: center;
  background-color: ${colors.ihk_lightblue};
  border-bottom: 3px solid ${colors.ihk_green};
  letter-spacing: 0.8pt;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin: 2.5rem 0 1rem 0;
`;

const StLink = styled.a`
  font-weight: 200;
  font-size: 2rem;
  cursor: pointer;
  text-decoration: none !important;
  text-align: center;
  font-family: "Asap Condensed", sans-serif;
  color: ${colors.ihk_darkblue};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border: 1px solid ${colors.ihk_darkgrey};
  border-radius: 5px;
  background-color: ${colors.ihk_lightblue};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media only screen and (max-width: 675px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 450px) {
    font-size: 1rem;
  }

  :hover {
    color: ${colors.ihk_darkblue};
    opacity: 0.85;
  }
`;

const FullWidthContainer = styled.div`
  position: relative;
  z-index: 0;
  margin: 0 -600rem;
  background: ${colors.ihk_lightgrey};
  padding: 1.5rem 600rem 2rem;

  :before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 598.5rem;
    right: 598.5rem;
    background: ${colors.ihk_lightgrey};
  }
`;

const TextImageWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  height: auto;
  margin: 0.5rem 1rem 1rem 1rem;
`;

const TextImageWrapperFloatRight = styled(TextImageWrapper)`
  float: right;
  max-width: 450px;

  @media only screen and (min-width: 1000px) and (max-width: 1200px) {
    float: none;
    margin: 0 1rem 1rem;
    min-width: 450px;
  }

  @media only screen and (max-width: 800px) {
    float: unset;
    margin: 0 auto 1rem;
  }
`;

const ColouredFullWidthBanner = styled.div`
  background-color: ${colors.ihk_lightgrey};
  scroll-margin-top: 2rem;
`;

const StInfoImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  flex-direction: column;
`;

const StInfoGraphic = styled.img`
  max-width: 100%;
`;
const StContainer = styled.div`
  display: block;

  @media only screen and (min-width: 1000px) and (max-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;
const StButton = styled.a`
  background-color: ${colors.ihk_green};
  color: ${colors.ihk_darkblue};
  padding: 0.6rem 1.2rem;
  font-weight: 600;
  font-family: "Asap Condensed", sans-serif;
  letter-spacing: 0.8pt;
  cursor: pointer;

  &:hover {
    color: ${colors.ihk_darkblue};
  }
`;

interface IndexProps {
  // eslint-disable-next-line
  data: any;
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  useEffect(() => {
    ImageMap("img[usemap]");
  });

  const createClickableArea = ({
    coords,
    link,
    alt,
    title,
  }: {
    coords: string;
    link: string;
    alt: string;
    title: string;
  }) => {
    return (
      <area shape="rect" coords={coords} alt={alt} href={link} title={title} />
    );
  };

  const imageMap = (
    <map name={"infoMap"}>
      {createClickableArea({
        coords: "454,264,639,322",
        link: "#merck",
        alt: "MERCK",
        title: "MERCK",
      })}
      {createClickableArea({
        coords: "211,437,63,347",
        link: "#juwoe",
        alt: "Juwö Poroton-Werke",
        title: "Juwö Poroton-Werke",
      })}
      {createClickableArea({
        coords: "426,395,292,327",
        link: "#schott",
        alt: "SCHOTT AG",
        title: "SCHOTT AG",
      })}
      {createClickableArea({
        coords: "653,360,835,417",
        link: "#pirelli",
        alt: "Pirelli Deutschland GmbH",
        title: "Pirelli Deutschland GmbH",
      })}
      {createClickableArea({
        coords: "685,152,808,233",
        link: "#vacuum",
        alt: "Vacuumschmelze GmbH & Co. KG",
        title: "Vacuumschmelze GmbH & Co. KG",
      })}
      {createClickableArea({
        coords: "478,34,630,104",
        link: "#hewi",
        alt: "HEWI GmbH",
        title: "HEWI GmbH",
      })}
    </map>
  );

  return (
    <Layout>
      <SEO title={"ETA-Metropol"} description={"Eta-Metropol"} lang={"de"} />
      <div className="container mb-5">
        <StHeadline>Netzwerk für Energieeffizienz und Klimaschutz</StHeadline>
        <StH3>Gemeinsam sparen wir 25.000 MWh in 2022 und 2023 ein</StH3>
        <p>
          Die Senkung von Energiekosten ist eine komplexe Herausforderung für
          viele Unternehmen. Kontinuierlich steigende Strompreise, Steuern und
          Umlagen müssen gemanaged und Innovationen in Produktionsprozesse
          integriert werden. Das Netzwerk ETA-Metropol Rhein Main Neckar für
          Energieeffizienz und Klimaschutz will im Verbund mit anderen
          Unternehmen diese Herausforderungen gemeinsam angehen. Dafür haben
          sich die Industrie- und Handelskammern (IHK) aus den Metropolregionen
          Rhein-Neckar und Frankfurt-Rhein-Main zusammengeschlossen. Damit wurde
          die erste bundesländerübergreifende Kooperation verwirklicht und eine
          Plattform für große Unternehmen geschaffen, die einen regelmäßigen
          Austausch ermöglicht. Unser Ziel ist es, gemeinsam für mehr
          Energieeffizienz und Klimaschutz in den Metropolregionen einzutreten
          und einen Beitrag zur Begrenzung der globalen Erderwärmung zu leisten.
        </p>
        <p style={{ marginTop: "1.8rem", marginBottom: "2rem" }}>
          <StButton href="#events">mehr Infos</StButton>
        </p>
        <IHKLogos />
      </div>
      <StInfoImageWrapper className={"container"}>
        <StInfoGraphic
          useMap={"#infoMap"}
          src={IHK_INFOGRAFIK}
          alt={"Infografik"}
        />
        {imageMap}

        <StLink href={"#contact"}>
          Kontaktieren Sie uns, um Teil des Netzwerks zu werden
        </StLink>
      </StInfoImageWrapper>

      <div className={"container mb-5"} id={"events"}>
        <StH4>Veranstaltungen in 2023</StH4>
        <Events items={eventList2023} />
        <StH4>Vergangene Veranstaltungen aus 2022</StH4>
        <Events items={eventList2022} />
      </div>

      <ColouredFullWidthBanner id={"vorteile"} className="container mb-5">
        <FullWidthContainer>
          <StH2>Zusammenarbeit eröffnet Chancen</StH2>
          <TileGroup />
        </FullWidthContainer>
      </ColouredFullWidthBanner>

      <div
        className="container mb-5 d-flex flex-column"
        id={"partner"}
        style={{ scrollMarginTop: "4rem" }}
      >
        <StH2>
          Teil der Initiative für Energieeffizienz- und Klimaschutz-Netzwerke{" "}
          <br />
          der Bundesregierung und Wirtschaftsverbände
        </StH2>
        <StContainer>
          <TextImageWrapperFloatRight>
            <Img fluid={data && data["initiative"].childImageSharp.fluid} />
          </TextImageWrapperFloatRight>
          <p>
            Die Bundesregierung und die unterzeichnenden Wirtschaftsverbände
            sind sich einig, dass eine Steigerung der Energieeffizienz und des
            Klimaschutzes für die internationale Wettbewerbsfähigkeit der
            deutschen Wirtschaft und die Erschließung neuer Geschäftsmodelle und
            Märkte eine zentrale Aufgabe der Politik ist. Sie stimmen auch darin
            überein, dass die Energieeffizienz und Klimaschutz in der deutschen
            Wirtschaft wirksam und effektiv vorangebracht werden kann, wenn die
            Unternehmen diese Aufgabe in die eigenen Hände nehmen. Aufgrund des
            erfolgreichen freiwilligen Engagements der Wirtschaft in der
            Vergangenheit haben die Bundesregierung und 21 Verbände der
            Wirtschaft vereinbart, ab 2021 bis einschließlich 2025 die
            Initiative für Energieeffizienz- und Klimaschutz-Netzwerke
            auszubauen und fortzusetzen. Das Netzwerk ETA-Metropol Rhein Main
            Neckar ist Teil dieser Bundesinitiative und möchte einen Beitrag für
            Energieeffizienz und Klimaschutz leisten.
          </p>
        </StContainer>
      </div>

      <ColouredFullWidthBanner id={"vorteile"} className="container mb-5">
        <FullWidthContainer>
          <StH2>Unternehmensstimmen der Netzwerkmitglieder</StH2>
          <Companies />
        </FullWidthContainer>
      </ColouredFullWidthBanner>

      <div
        className="container"
        id={"contact"}
        style={{ scrollMarginTop: "4rem" }}
      >
        <StH2>
          Sie möchten auch mit Ihrem Unternehmen dem Netzwerk
          <br />
          ETA-Metropol Rhein Main Neckar beitreten?
        </StH2>

        <AsPartner />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    metropol: file(relativePath: { eq: "SVGLogo.svg" }) {
      publicURL
    }
    outsideHouse: file(relativePath: { eq: "house-outside.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    initiative: file(relativePath: { eq: "initiative.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    insideFactory: file(relativePath: { eq: "factory-inside.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
